<template>
  <div class="paragraph">
    <h3>{{content.metadata.pres_title}}</h3>
    <div v-html="content.metadata.whoamiintro">
   </div>
  </div>
</template>

<script>
export default {
    name: 'Description',
    props: ['content']
}
</script>

<style scoped lang="scss">
    @import '@/styles/constants.scss';

    .paragraph {
        color: map-get($colors, primary);
        font-size: 2rem;
        .begin {
            color: map-get($colors, secondary);
        }
        h3 {
            color: map-get($colors, secondary);
            font-size: 2.3rem;
        }
    }
</style>