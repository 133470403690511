<template>
  <transition name="fade" tag="div" class="wrapper" mode="out-in">
    <div class="wrapper" v-if="isLoaded" id="app">
      <LandingPage :user="user" />
      <Description :user="user" :content="data" :links="getSocialMedia()" />
      <Experience :content="data.metadata" :header="getHeader('experience')"/>
      <Skills :metadata="data.metadata" :header="getHeader('skills')" />
      <Projects :content="data.metadata" :header="getHeader('projects')" />
      <Footer :user="user" :links="getSocialMedia()" />
    </div>
  </transition>
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import Description from "./components/Description.vue";
import Experience from "./components/Experience.vue";
import Skills from "./components/Skills.vue";
import Projects from "./components/Projects.vue";
import Footer from "./components/Footer.vue";

import { createBucketClient } from '@cosmicjs/sdk';
/* get the env variables */
const VUE_APP_BUCKET_SLUG = process.env.VUE_APP_BUCKET_SLUG;
const VUE_APP_READ_KEY = process.env.VUE_APP_READ_KEY;


export default {
  name: "App",
  components: {
    LandingPage,
    Description,
    Experience,
    Skills,
    Projects,
    Footer,
  },
  data: () => ({
    isLoaded: false,
    user: {},
    posts: [],
  }),
  methods: {
    getHeader(type) {
      return this.data.metadata.headers.find((item) => {
        return item.section === type;
      });
    },
    getSocialMedia() {
      return this.data.metadata.socialmedia;
    },
  },
  async created() {
    document.body.classList.add("loading");
      /* new cosmic version request */
      const cosmic = createBucketClient({
            bucketSlug: VUE_APP_BUCKET_SLUG ,
            readKey: VUE_APP_READ_KEY,
            })
        let data = await cosmic.objects.findOne({
            type: "portfolio-contents",
        }).props("slug,title,metadata")
      .depth(1);
      data = data.object;
      this.data = data;
      console.log(this.data);
      this.user = {
          name: data.metadata.name,
          status: data.metadata.status,
          email: data.metadata.email,
          phone: data.metadata.phone,
          city: data.metadata.city,
          lang: data.metadata.lang,
          photo: data.metadata.photo
      }
      this.isLoaded = true;
      this.$nextTick(() => document.body.classList.remove("loading")); 
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/constants.scss";

#app {
  font-family: map-get($ff, italic), serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.wrapper {
  height: 100%;
}
</style>
